<template>
  <b-container fluid class="p-0">
    <div>
      <h4>Chat with us&nbsp;&nbsp;<i class="fa-solid fa-arrows-rotate pointer primary-color" title="Refresh messages" @click="refresh()"></i></h4>
    </div>
    <MessageAdd class="mt-3" v-if="propAdm" :propOpenedInModal="false" :propAdm="propAdm"  propModuleType="admission" @emitMessageListUpdate="emitMessageListUpdate"/>
    <div class="messageList mt-5  row w-100" :key="totalRows">
      <div class="mt-3 mb-2 col-12" v-for="msg in messageObjList" :key="msg.msg_id">
        <b-list-group-item class="d-flex meesageDetail pr-3 col-10 col-md-7 col-sm-8" style="background: #f4f4f4;" :class="msg.user_id === userData.user_id ? 'messageRight' : ''">
          <b-avatar :size="45" variant="info" v-if="msg.user_role === 'USERROLE11111'" :src="gidelogo"></b-avatar>
          <b-avatar :size="45" variant="info" v-else-if="msg.user_role ==='USERROLE11114' " :src="msg.user_img"></b-avatar>
          <b-avatar :size="45" variant="info" v-else-if="msg.user_role ==='USERROLE11115' " :src="msg.org_image"></b-avatar>
          <b-avatar :size="45" v-else :variant="getBgColorForLetter(msg.user_role === 'USERROLE11115' ? msg.org_image : msg.user_img)" :text="getFirstLetterOfAString(msg.user_name)" >
          </b-avatar>
          <div class="pl-3 w-100">
            <h6 v-if="msg.user_role ==='USERROLE11111' ">
              Gide Admin
            </h6>
            <h6 v-else-if="msg.user_role ==='USERROLE11115' ">
              {{msg.org_name}}
            </h6>
            <h6 v-else>
              {{msg.user_name}}
            </h6>
            <div class="d-flex flex-column rounded pointer  mb-2">
              <div class="mb-0" v-if="msg.message">
                <div class="float-left">{{ msg.message }}</div>
                <br>
                <div class="messageTime">{{ msg.created_on | dateFormatMsg}}</div>
              </div>
            </div>
          </div>
        </b-list-group-item>
      </div>
    </div>
  </b-container>

</template>
<style lang="scss" scoped>
.messageList::-webkit-scrollbar {
    display: none;
}
 .messageList {
  max-height: 500px;
  overflow: auto;
  border: 1px solid var(--iq-border-light);
  .meesageDetail {
    border: none;
    width: fit-content;
    word-break: break-all;
  }
  .messageRight {
    float: right;
    border-radius: 10px 0px 0px 10px;
  }
  .messageTime {
    font-size: small;
    float: right;
    margin-top: 5px;
  }
  .refresh{
    background: var(--iq-primary);
    color:var(--iq-light-secondary);
  }
 }
</style>
<script>
import { Messages } from "../../../FackApi/api/message.js"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import Utility from "../../../Utils/utility"
import MessageAdd from "./MessageAdd.vue"

export default {
  name: "MessageList",
  props: {
    propAdm: {
      type: String
    },
    propOpenedInModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MessageAdd
  },
  data () {
    return {
      cvCardTitle: "Messages",
      cvbtnAddNew: "Add New",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit Message",
      cvAddModalHeader: "Add Message",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Message List Response",
      showModelMessageAdd: false,
      showModelMessageEdit: false,
      showModelMessageDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left", sortable: true },
        { label: "Sender Name", key: "user_name", class: "text-left", sortable: true },
        { label: "module obj id", key: "module_obj_id", class: "text-left", sortable: true },
        { label: "modue type", key: "modue_type", class: "text-left", sortable: true },
        { label: "message", key: "message", class: "text-left", sortable: true },
        { label: "Created On ", key: "created_on", class: "text-left", sortable: true },
        { label: "Action ", key: "action", class: "text-left", sortable: false }
      ],
      messageObjList: null,
      messageEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      gidelogo: process.env.VUE_APP_GIDE_ADMIN_USER_IMAGE
    }
  },
  mounted () {
    this.messageList()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    refresh () {
      this.messageList()
    },
    /**
     * Load More Chat
     */
    loadMore () {
      this.currentPage = this.currentPage + 1
      this.messageList()
    },
    /**
     * getFirstLetterOfAString
     */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 2)
      }
    },
    /**
     * getBgColorForLetter
     */
    getBgColorForLetter (name) {
      let firstLetter = this.getFirstLetterOfAString(name)
      return Utility.getBgColorForLetter(this, firstLetter)
    },
    /**
       * getFormattedDateTime
       */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
       * messageList
       */
    async messageList () {
      try {
        let admissionId = this.propAdm
        let messageListResp = await Messages.messageList(this, admissionId, this.currentPage)
        if (messageListResp.resp_status) {
          this.messageObjList = messageListResp.resp_data.data
          this.totalRows = messageListResp.resp_data.count
        }
        else {
          this.toastMsg = messageListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at messageList() and Exception:", err.message)
      }
    },
    /**
       * messageAdd
       */
    messageAdd (type) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/message_add")
        }
        else {
          this.showModelMessageAdd = true
        }
      }
      catch (err) {
        console.error("Exception occurred at messageAdd() and Exception:", err.message)
      }
    },
    /**
       * messageEdit
       */
    messageEdit (type, item) {
      try {
        if (type === "FULLPAGE") {
          this.$router.push("/admin/message_edit/" + this.messageEditObj.msg_id)
        }
        else {
          this.messageEditObj = item
          this.showModelMessageEdit = true
        }
      }
      catch (err) {
        console.error("Exception occurred at messageEdit() and Exception:", err.message)
      }
    },
    /**
       * showMessageDeleteDialog
       */
    showMessageDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelMessageDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showMessageDeleteDialog() and Exception:", err.message)
      }
    },
    /**
       * messageDelete
       */
    async messageDelete () {
      try {
        let messageDelResp = await Messages.messageDelete(this, this.delObj.msg_id)
        await ApiResponse.responseMessageDisplay(this, messageDelResp)

        if (messageDelResp && !messageDelResp) {
          this.showModelMessageDelete = false
          return false
        }

        let index = this.messageObjList.indexOf(this.delObj)
        this.messageObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelMessageDelete = false
      }
      catch (err) {
        console.error("Exception occurred at messageDelete() and Exception:", err.message)
      }
    },
    /**
       * closeMessageAddModal
       */
    closeMessageAddModal (messageAddData) {
      try {
        if (messageAddData) {
          if (this.messageObjList && this.messageObjList.length >= 1) {
            let msgObjLength = this.messageObjList.length
            let lastId = this.messageObjList[msgObjLength - 1]["id"]
            messageAddData.id = lastId + 1
          }
          else {
            this.messageObjList = []
            messageAddData.id = 11111
          }

          // messageAddData.created_on = moment()
          // this.messageObjList.unshift(messageAddData)
          // this.totalRows = this.totalRows + 1
        }

        this.showModelMessageAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closemessageAddModal() and Exception:", err.message)
      }
    },
    /**
       * closeMessageEditModal
       */
    closeMessageEditModal () {
      try {
        this.showModelMessageEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeMessageEditModal() and Exception:", err.message)
      }
    },
    /**
     * emitMessageListUpdate
     */
    emitMessageListUpdate () {
      this.messageList()
    }
  }
}
</script>
