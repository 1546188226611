/**
 * userEmailValidation
 * validates emails by checking if it has a "+" symbol in it
 */
function userEmailValidation (context, userEmail, customErrorMsg = null) {
  if (userEmail && userEmail.includes("+")) {
    let inputEmail = userEmail
    let plusSymbolIndex = inputEmail.indexOf("+")
    let atSymbolIndex = inputEmail.indexOf("@")

    let emailWithoutPlus = inputEmail.substring(0, plusSymbolIndex) + inputEmail.substring(atSymbolIndex)
    if (emailWithoutPlus !== process.env.VUE_APP_TESTER_MAIL_ID) {
      if (customErrorMsg) {
        context.toastMsg = customErrorMsg
      }
      else {
        context.toastMsg = "Invalid Email"
      }
      context.toastVariant = "danger"
      context.showToast = true
      return false
    }
    // else its the official tester email that we allow like "tester+1@gide.ai"
  }
  return true
}

export {
  userEmailValidation
}
