/*eslint-disable */
// import USEREXTRACCModel from '../../Model/USEREXTRACC'
import request from '../../Utils/curl'
import Utility from "../../Utils/utility";

let uexccTypes = {
  /**
   * userextraccList
   */
  async userextraccList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_extracc_type_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at userextraccList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

  /**
   * userextraccView
   */
  async userextraccView (context, userextraccId) {
    try {      
      let post_data = new FormData();
      post_data.append('uexcc_id', userextraccId);
      return await request.curl(context, "user_extracc_type_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at userextraccView() and Exception:",err.message)
    }    
  },

  /**
   * userextraccAdd
   */
  async userextraccAdd (context, userextraccObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userextraccObj) {
        post_data.append(key, userextraccObj[key]);
      }
      return await request.curl(context, "user_extracc_type_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userextraccAdd() and Exception:',err.message)
    }
  },

  /**
   * userextraccEdit
   */
  async userextraccEdit (context, userextraccObj) {
    try{
      let post_data = new FormData();
    
      for (let key in userextraccObj) {
        post_data.append(key, userextraccObj[key]);
      }
  
      return await request.curl(context, "user_extracc_type_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at userextraccEdit() and Exception:',err.message)
    }
  },

  /**
   * userextraccDelete
   */
  async userextraccDelete (context, userextraccId) {
    try{
      let post_data = new FormData();
      
      post_data.append('uexcc_id', userextraccId);

      return await request.curl(context, "user_extracc_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at userextraccDelete() and Exception:',err.message)
    }
  },

  /**
   * userextraccList
   */
  async userextraccUnivList (context, whereFilter=null) {
    try{
      Utility.showLoader = true;
      Utility.loadingMsg = "Please wait....";

      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "user_extracc_type_univ_list", post_data)
        .then(async response => {
          return response
        });
    }
    catch(err){
      console.error('Exception occurred at userextraccList() and Exception:',err.message)
    }
    finally{
      Utility.showLoader = false;
    }
  },

 /**
  * excTypeDistinct
  */
  async excTypeDistinct (context) {
    console.log("userextraccObj fake api",)
    try {      
      let post_data = new FormData();
      return await request.curl(context, "user_extracc_type_distinct", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at excTypeDistinct() and Exception:",err.message)
    }
  }
}

export {
  uexccTypes
}
