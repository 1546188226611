/*eslint-disable */
// import User_ParentModel from '../../Model/User_Parent'
import request from '../../Utils/curl'

let UserParent = {
  /**
   * user_parentList
   */
  async user_parentList (context, userId) {
    try{
      let post_data = new FormData();
      post_data.append('current_user_id', userId)
      return await request.curl(context, "user_parent_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at user_parentList() and Exception:',err.message)
    }
  },

  /**
   * user_parentView
   */
  async user_parentView (context, user_parentId) {
    try {
      
      let post_data = new FormData();
      post_data.append('upar_id', user_parentId);
      return await request.curl(context, "user_parent_view", post_data)
      .then(async response => {
        return response;
      });
    }
    catch (err) {
      console.error("Exception occurred at user_parentView() and Exception:",err.message)
    }
    
  },

  /**
   * user_parentAdd
   */
  async user_parentAdd (context, user_parentObj) {
    try{
    let post_data = new FormData();
    
    for (let key in user_parentObj) {
      post_data.append(key, user_parentObj[key]);
    }

    return await request.curl(context, "user_parent_add", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_parentAdd() and Exception:',err.message)
    }
  },

  /**
   * user_parentEdit
   */
  async user_parentEdit (context, user_parentObj) {
    try{
    let post_data = new FormData();
    
    for (let key in user_parentObj) {
      post_data.append(key, user_parentObj[key]);
    }

    return await request.curl(context, "user_parent_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_parentEdit() and Exception:',err.message)
    }
  },
  /**
   * user_parentEdit
   */
  async user_parentVerifyEmailAndMobile (context, user_parentObj) {
    try{
    let post_data = new FormData();
    
    for (let key in user_parentObj) {
      if (user_parentObj[key]) {
        post_data.append(key, user_parentObj[key]);
      }
    }

    return await request.curl(context, "verify_user_parent_email_and_mobile", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at verify_user_parent_email_and_mobile() and Exception:',err.message)
    }
  },

  /**
   * user_parentDelete
   */
  async user_parentDelete (context, user_parentId) {
    try{
    let post_data = new FormData();
    
    post_data.append('upar_id', user_parentId);

    return await request.curl(context, "user_parent_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at user_parentDelete() and Exception:',err.message)
    }
  },

  /**
   * user_parentSendEmailOtp
   */
  async user_parentSendEmailOtp (context, user_parentObj) {
    try{
    let post_data = new FormData();
    
    for (let key in user_parentObj) {
      post_data.append(key, user_parentObj[key]);
    }

    return await request.curl(context, "user_parent_send_email_otp", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_parentSendEmailOtp() and Exception:',err.message)
    }
  },

  /**
   * user_parentSendMobileOtp
   */
  async user_parentSendMobileOtp (context, user_parentObj) {
    try{
    let post_data = new FormData();
    
    for (let key in user_parentObj) {
      post_data.append(key, user_parentObj[key]);
    }

    return await request.curl(context, "user_parent_send_mobile_otp", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at user_parentSendMobileOtp() and Exception:',err.message)
    }
  }
}

export {
  UserParent
}
