<template lang="">
  <div class="row w-100 educationForm m-0">
    <!-- Yjs Editor -->
    <b-modal
      v-model="showEditDocumentModal"
      scrollable
      :title="cvEditDocumentModalHeader"
      :noCloseOnBackdrop="true"
      size="xxl"
      hide-header
      hide-footer
    >
      <SpcmEssayEditor :propYJSDocObj="documentEditObj" :propOpenedInModal="true" @emitCloseuserYjsdocsHistoryAddModal="CloseuserYjsdocsHistoryAddModal"/>
    </b-modal><!-- Yjs Editor -->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>

    <ValidationObserver ref="extraCurrucularForm" class="row w-100 mt-4">
      <!-- School selection dropdown -->
      <div class="form-group col-6">
        <label for="usp_edu_id">School</label>
        <select v-model="vmExtraCurricularForm['usp_edu_id']" class="form-control" title="Select School" @change="onSchoolChange">
          <option v-for="(schObj, index) of cvSchoolOptions" :key="(index+1)" :value="schObj.value">
            {{schObj.label}}
          </option>
        </select>
      </div><!-- School selection dropdown -->

      <!-- User's Class, only for display purpouses -->
      <div class="form-group col-6">
        <label for="user_class">Class</label>
        <ValidationProvider name="Class"  v-slot="{ errors }" rules='required'>
          <input readonly :value="userClass" type="text" class="form-control mb-0" id="user_class">
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div><!-- User's Class, only for display purpouses -->

      <!-- Extracurricular Type -->
      <div class="form-grou col-12 col-sm-6 col-lg-6">
        <label for="exc_type">Type</label>
        <ValidationProvider name="Type"  v-slot="{ errors }" rules='required'>
          <select v-model="vmExtraCurricularForm['exc_type']" title="Extracurricular type" class="form-control" @change="onExcTypeChange">
            <option v-for="(excTypeObj, index) of cvExtraCurricularTypeList" :key="(index+1)" :value="excTypeObj.value">
              {{excTypeObj.label}}
            </option>
          </select>
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div><!-- Extracurricular Type -->

      <!-- University for which essay is to be submited -->
      <div class="form-group col-6" v-if="selectedExcType && selectedExcType == 'UNIV_ESSAY'">
        <label for="exc_univ">University</label>
        <select v-model="vmExtraCurricularForm['exc_univ']" class="form-control" title="Select University" @change="onUnivChange">
          <option v-for="(univObj, index) of cvUniversityOptions" :key="(index+1)" :value="univObj.value">
            {{univObj.label}}
          </option>
        </select>
      </div><!-- University for which essay is to be submited -->

      <!-- Extracurricular Title / Essay Topic -->
      <div class="form-group col-6" v-if="(selectedExcType && (selectedExcType != 'COMMONAPP_ESSAY' && selectedExcType != 'UNIV_ESSAY')) || !selectedExcType">
        <label for="exc_title">Title</label>
        <ValidationProvider name="Title"  v-slot="{ errors }" rules='required|min:5|max:50'>
          <input v-model="vmExtraCurricularForm['exc_title']" type="text" class="form-control mb-0" id="exc_title">
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <!-- These fields get enabled when extracurricular type will be university or commonapp essays -->
      <div class="form-group col-12 " v-else>
        <!-- Loops through all the essay topics for a particular university -->
        <div v-for="(topic, index) in cvEssayTopicOptions" :key="index">
          <p for="exc_title" v-if="cvEssayTopicOptions.length > 0" class="mt-4 ml-2"><b>Topic:&nbsp; <span v-html="topic.label"></span></b></p>
          <!-- <multiselect :options="cvEssayTopicOptions" v-model="vmExtraCurricularForm['exc_title']" id="exc_title" label="label">
          </multiselect> -->
          <!-- <select v-model="vmExtraCurricularForm['exc_title']" class="form-control" title="Select Essay Topic" id="exc_title">
            <option v-for="(essayTopicObj, index) of cvEssayTopicOptions" :key="(index+ 'essayTopicObj')" :value="essayTopicObj.value">
              {{essayTopicObj.value}}
            </option>
          </select> -->

          <!-- <p class="mb-2"></p> -->
          <!-- Extracurricular Description -->
          <div class="form-group col-12" v-if="!editMode">
            <label for="exc_desc">Description</label>
            <ValidationProvider name="Description" rules='required|min:50|max:500' v-slot="{ errors }">
              <textarea :minlength="topic.uexcc_min_length" :maxlength="topic.uexcc_max_length" autogrow v-model="topic.essay" style="height:unset;" type="textarea" class="form-control mb-0" id="exc_desc"></textarea>
              <span v-if="topic.essay" :class="(topic.essay.length < topic.uexcc_min_length || topic.essay.length > topic.uexcc_max_length) ? 'text-danger': 'text-success'">{{topic.essay.length}}/ {{topic.uexcc_max_length}}</span>
              <br>
              <template v-if="topic.essay">
                <span class="text-danger" v-if="topic.essay.length < topic.uexcc_min_length">{{ errors[0] ? `Min ${topic.uexcc_min_length} charactrers are required` : '' }}</span>
                <span class="text-danger" v-if="topic.essay.length > topic.uexcc_max_length">{{ errors[0] ? `Max ${topic.uexcc_max_length} charactrers are allowed` : '' }}</span>
              </template>
              <template v-else>
                <span class="text-danger">{{ errors[0] }}</span>
              </template>
            </ValidationProvider>
          </div><!-- Extracurricular Description -->

          <!-- Google Docs URL input -->
          <div class="form-group col-8">
            <label for="googledoc">Google Docs Link (If any)</label>
            <input v-model="topic.yjsdocs_google_doc_url" type="text" class="form-control mb-0" id="googledoc">
          </div><!-- Google Docs URL input -->
        </div><!-- Loops through all the essay topics for a particular university -->
      </div><!-- Extracurricular Title / Essay Topic -->
      <!-- These fields get enabled when extracurricular type will be university or commonapp essays -->

      <!-- Organisation where the extracurricular activity has been performed or participated -->
      <div class="form-group col-6" v-if="(selectedExcType && (selectedExcType != 'COMMONAPP_ESSAY' && selectedExcType != 'UNIV_ESSAY')) || !selectedExcType">
        <label for="exc_org">Organisation</label>
        <ValidationProvider name="Organisation"  v-slot="{ errors }">
          <input v-model="vmExtraCurricularForm['exc_org']" type="text" class="form-control mb-0" id="exc_org">
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
      </div><!-- Organisation where the extracurricular activity has been performed or participated -->

      <!-- These fields will be enabled for extracurriculars apart from university and commonapp essays-->
      <!-- Extracurricular Description -->
      <div class="form-group col-12" v-if="!editMode && ((selectedExcType && (selectedExcType != 'COMMONAPP_ESSAY' && selectedExcType != 'UNIV_ESSAY')) || !selectedExcType)">
        <label for="exc_desc">Description</label>
        <ValidationProvider name="Description" rules='required|min:50|max:500' v-slot="{ errors }">
          <textarea autogrow v-model="vmExtraCurricularForm['exc_desc']" style="height:unset;" type="textarea" class="form-control mb-0" id="exc_desc">
          </textarea>
          <span v-if="vmExtraCurricularForm['exc_desc']" :class="(vmExtraCurricularForm['exc_desc'].length < 50 || vmExtraCurricularForm['exc_desc'].length > 500) ? 'text-danger': 'text-success'">{{vmExtraCurricularForm['exc_desc'].length}}/ 500</span>
          <br>
          <template v-if="vmExtraCurricularForm['exc_desc']">
            <span class="text-danger" v-if="vmExtraCurricularForm['exc_desc'].length < 50">{{ errors[0] ? 'Min 50 charactrers are required' : '' }}</span>
            <span class="text-danger" v-if="vmExtraCurricularForm['exc_desc'].length > 499">{{ errors[0] ? 'Max 500 charactrers are allowed' : '' }}</span>
          </template>
          <template v-else>
            <span class="text-danger">{{ errors[0] }}</span>
          </template>
        </ValidationProvider>
      </div><!-- Extracurricular Description -->
      <!-- Edit Description using yjs editor in edit mode -->
      <div class="form-group col-12" v-if="editMode">
        <label for="exc_desc">Description
          <span class="pointer primary-color" style="font-size: 1.2rem" title="Edit Description" @click="openYjsEditor(vmExtraCurricularForm)"><i class="fa-solid ri-pencil-line"></i></span>
        </label><br>
        <div v-html="vmExtraCurricularForm['exc_desc']"></div>
      </div><!-- Edit Description using yjs editor in edit mode -->

      <!-- Google Docs URL input -->
      <div class="form-group col-8" v-if="(selectedExcType && (selectedExcType != 'COMMONAPP_ESSAY' && selectedExcType != 'UNIV_ESSAY')) || !selectedExcType">
        <label for="googledoc">Google Docs Link (If any)</label>
        <input v-model="vmExtraCurricularForm['yjsdocs_google_doc_url']" type="text" class="form-control mb-0" id="googledoc">
      </div><!-- Google Docs URL input -->
      <!-- These fields will be enabled for extracurriculars apart from university and commonapp essays-->

      <!-- Video Links -->
      <div class="form-group col-10 markRow" v-for="(video, index) in video_link_list" :key="index">
        <label for="exc_video_link">Video Link (Only Youtube links accepted)</label>
        <ValidationProvider name="Video Link"  v-slot="{ errors }">
          <input v-model="video.url" type="text" class="form-control mb-0" id="exc_video_link">
          <span class="text-danger">{{ errors[0] }}</span>
        </ValidationProvider>
        <span class="addIcon">
          <i class="fa-solid fa-circle-plus mr-2" @click="addExcVideoLinkInput()"></i>
          <i class="fa-solid fa-circle-xmark" @click="removeExcVideoLinkInput(index)"></i>
        </span>
      </div><!-- Video Links -->

      <!-- Upload Documents -->
      <div class="row col-12 w-100 ml-1">
        <h6 class="educationTitle w-100 mb-2">Document Showcase (Only PDFs are acceptable, size less than 5mb)&nbsp;&nbsp;
          <label for="excAttachmentFileInput" class="pointer primary-color">(Click here to upload)&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
          <input type="file" accept="application/pdf,image/x-eps" class="dontShow" id="excAttachmentFileInput" @change="onExtraCurricularAttatachmentChange($event, vmExtraCurricularForm, 'excAttachmentFileInput')">
        </h6>
        <template v-if="vmExtraCurricularForm.exc_attach && vmExtraCurricularForm.exc_attach.length">
          <div v-for="(attachment, index) of parseToJSON(vmExtraCurricularForm.exc_attach)" :key="index">
            <div class="attachmentCol">
              <img v-if="attachment.content && attachment.content.includes('pdf') || attachment.type && attachment.type.includes('pdf')" :src="pdfLogo" alt="not found" @click="openFile(attachment.url)">
              <img v-else :src="attachment.url" alt="not found" @click="openFile(attachment.url)">
              <span class="addIcon extraCloseIcon" @click="vmExtraCurricularForm.exc_attach.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
            </div>
          </div>
        </template>
      </div><!-- Upload Documents -->

      <div class="row w-100 pt-4 mb-4 rightAlignDiv">
        <b-button class="ml-4" variant="primary" @click="editExtraCurricularActivity()" v-if="editMode">Update Extracurricular</b-button>
        <b-button @click="addExtraCurricularActivity()" variant="primary" type="button" v-else>Save</b-button>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import pdfLogo from "../../../assets_gide/img/logo/pdfLogo.png"
import { uexccTypes } from "../../../FackApi/api/UexccType.js"
import { UserProfileExc } from "../../../FackApi/api/userProfileExc"
import { User } from "../../../FackApi/api/user"
import ApiResponse from "../../../Utils/apiResponse"
// import Multiselect from "vue-multiselect"
import { YjsDocs } from "../../../FackApi/api/YjsDocs"
import SpcmEssayEditor from "../SPCM/SpcmEssayEditor.vue"

export default {
  name: "YjsDocsAttach",
  components: {
    // Multiselect,
    SpcmEssayEditor
  },
  props: {
    propUserDetails: {
      type: Object,
      default: null
    },
    propSchoolDetails: {
      type: Object,
      default: null
    },
    propOpenedInEditMode: {
      type: Boolean,
      default: false
    },
    propUspExcId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      cvExtraCurricularTypeList: [],
      cvEssayTopicOptions: [],
      cvUniversityOptions: [],
      cvSchoolOptions: [],
      userClass: null, // Only for display purpouses, comes from the education details
      vmExtraCurricularForm: {
        exc_attach: [],
        exc_video_link: []
      },
      video_link_list: [{ url: "" }],
      pdfLogo: pdfLogo,
      editMode: this.propOpenedInEditMode,
      selectedExcType: null,
      documentEditObj: null,
      showEditDocumentModal: false,
      cvEditDocumentModalHeader: "Edit Document Abouts",
      showToast: false,
      toastVariant: "default",
      toastMsg: null,
      toastTitle: this.propSchoolDetails ? "Extracurriculars" : "Documents"
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.populateExcTypes()
    // Resume Builder passes an empty object {} as school details, allowing the user to select the school, so if we want the extracurricular details to be emitted from the component and still want the user to select the school we pass empty object into propSchoolDetails
    if (!this.propSchoolDetails || Object.keys(this.propSchoolDetails).length == 0) {
      this.populateSchoolOptions()
    }
    else {
      this.cvSchoolOptions = [{ value: this.propSchoolDetails.id, label: this.propSchoolDetails.institute_name, class_name: this.propSchoolDetails.education.class_name }]
      this.vmExtraCurricularForm["usp_edu_id"] = this.propSchoolDetails.id
      this.userClass = this.propSchoolDetails.education.class_name
    }

    if (this.editMode) {
      this.viewUserProfileExc()
    }
  },
  methods: {
    /**
     * populateExcTypes
     */
    async populateExcTypes () {
      try {
        let excTypeList = await uexccTypes.userextraccList(this, { get_types_only: true })
        if (!excTypeList.resp_status) {
          ApiResponse.responseMessageDisplay(this, excTypeList)
          return
        }
        this.excTypeList = excTypeList.resp_data.data

        // No need to add essays when the component is opened from user profile and school details are passed into it
        if (this.propSchoolDetails || (this.propSchoolDetail && Object.keys(this.propSchoolDetails).length == 0)) {
          this.excTypeList = this.excTypeList.filter(e => e.uexcc_type_key != "COMMONAPP_ESSAY" && e.uexcc_type_key != "UNIV_ESSAY")
        }

        // when user chooses common app essays or university essays essay topics will get populated in a separate field so treat them as two different types
        excTypeList = []
        for (let excTypeObj of this.excTypeList) {
          if (excTypeObj.uexcc_type_key == "COMMONAPP_ESSAY") {
            excTypeList.push({ exc_type_key: excTypeObj.uexcc_type_key, value: excTypeObj.uexcc_type_id, label: "Common App Essay" })
          }
          else if (excTypeObj.uexcc_type_key == "UNIV_ESSAY") {
            excTypeList.push({ exc_type_key: excTypeObj.uexcc_type_key, value: excTypeObj.uexcc_type_id, label: "University Essay" })
          }
          else {
            excTypeList.push({ exc_type_key: excTypeObj.uexcc_type_key, value: excTypeObj.uexcc_type_id, label: excTypeObj.uexcc_type_val })
          }
        }

        this.cvExtraCurricularTypeList = excTypeList
      }
      catch (err) {
        console.error("Exception in populateExcTypes() and err: ", err.message)
      }
    },

    /**
     * populateEssayTopics
     */
    async populateEssayTopics (univId) {
      try {
        const payload = {}
        if (univId) {
          payload.uexcc_org_id = univId
        }
        else {
          // Find the type key
          let excTypeObj = this.cvExtraCurricularTypeList.find(e => e.value == this.vmExtraCurricularForm["exc_type"])
          if (excTypeObj) {
            payload.uexcc_type_key = excTypeObj.exc_type_key
          }
          else {
            console.error("Invalid extracurricular type ")
            return
          }
        }

        let essayTopicList = await uexccTypes.userextraccList(this, payload)
        if (!essayTopicList.resp_status) {
          ApiResponse.responseMessageDisplay(this, essayTopicList)
          return
        }
        essayTopicList = essayTopicList.resp_data.data

        this.cvEssayTopicOptions = essayTopicList.map((topicObj) => {
          return {
            label: topicObj.uexcc_type_val.replaceAll("\n", "<br/>"),
            value: topicObj.uexcc_type_val.replaceAll("\n", "<br/>"),
            essay: "",
            yjsdocs_google_doc_url: "",
            uexcc_type_id: topicObj.uexcc_type_id,
            uexcc_min_length: topicObj.uexcc_min_length,
            uexcc_max_length: topicObj.uexcc_max_length
          }
        })
      }
      catch (err) {
        console.error("Exception in populateEssayTopics() and err: ", err.message)
      }
    },

    /**
     * populateUniversities
     */
    async populateUniversities () {
      try {
        let univList = await uexccTypes.userextraccUnivList(this)
        if (!univList.resp_status) {
          ApiResponse.responseMessageDisplay(this, univList)
          return
        }

        univList = univList.resp_data.data
        this.cvUniversityOptions = univList.map((univObj) => {
          return { label: univObj.org_name, value: univObj.org_id }
        })
      }
      catch (err) {
        console.error("Exception in populateUniversities() and err: ", err.message)
      }
    },

    /**
     * populateSchoolOptions
     */
    async populateSchoolOptions () {
      try {
        let userId = null
        if (this.propUserDetails && this.propUserDetails.user_id && (this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118")) {
          userId = this.propUserDetails.user_id
        }
        else {
          userId = this.userData.user_id
        }

        const userProfileResp = await User.userProfileView(this, userId)
        if (!userProfileResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, userProfileResp)
          return
        }
        this.cvSchoolOptions = userProfileResp.resp_data.data.user_profile.education.map((edu) => {
          return { value: edu.id, label: edu.institute_name, class_name: edu.education.class_name }
        })
      }
      catch (err) {
        console.error("Exception in populateSchoolOptions() and err: ", err.message)
      }
    },

    /**
     * onExcTypeChange
     */
    onExcTypeChange () {
      // get the exc_type_key(NB: the following code works for exc other than UNIV and COMMON APP essays, they are handled via cvEssayTopicOptions)
      let excTypeKey = this.cvExtraCurricularTypeList.find(e => e.value == this.vmExtraCurricularForm["exc_type"])
      if (excTypeKey) {
        this.selectedExcType = excTypeKey.exc_type_key
        if (this.selectedExcType == "UNIV_ESSAY") {
          this.populateUniversities()
          this.cvEssayTopicOptions = []
        }
        if (this.selectedExcType == "COMMONAPP_ESSAY") {
          this.populateEssayTopics()
          this.cvEssayTopicOptions = []
        }
      }
    },

    /**
     * onSchoolChange
     */
    onSchoolChange () {
      let schDetails = this.cvSchoolOptions.find(e => e.value == this.vmExtraCurricularForm["usp_edu_id"])
      if (schDetails) {
        this.userClass = schDetails.class_name
      }
    },

    /**
     * onUnivChange
     */
    onUnivChange () {
      if (this.vmExtraCurricularForm["exc_univ"]) {
        this.populateEssayTopics(this.vmExtraCurricularForm["exc_univ"])
      }
    },

    /**
     * onExtraCurricularAttatachmentChange
     */
    onExtraCurricularAttatachmentChange (event, extraCurricular, inputId) {
      const fileList = event.target.files

      if (fileList && fileList.length) {
        for (let file of fileList) {
          if (file.type.includes("pdf")) {
            var reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = async (e) => {
              file.url = e.currentTarget.result
              file.fileName = file.name

              if (!extraCurricular.exc_attach) {
                extraCurricular.exc_attach = []
              }

              if (typeof extraCurricular.exc_attach === "string") {
                extraCurricular.exc_attach = JSON.parse(extraCurricular.exc_attach)
              }

              extraCurricular.exc_attach.unshift(file)

              const ele = document.getElementById(inputId)
              if (ele) {
                ele.value = ""
              }
            }
          }
          else {
            alert("Only Pdf allowed")
          }
        }
      }
    },

    /**
     * viewUserProfileExc
     */
    async viewUserProfileExc () {
      try {
        const UserProfileExcViewResp = await UserProfileExc.user_profile_excView(this, this.propUspExcId)
        if (!UserProfileExcViewResp.resp_status) {
          ApiResponse.responseMessageDisplay(this, UserProfileExcViewResp)
          return
        }
        this.vmExtraCurricularForm = UserProfileExcViewResp.resp_data
        if (this.vmExtraCurricularForm["exc_video_link"]) {
          this.vmExtraCurricularForm["exc_video_link"] = this.parseToJSON(this.vmExtraCurricularForm["exc_video_link"])
          this.video_link_list = this.vmExtraCurricularForm["exc_video_link"]
        }

        if (this.vmExtraCurricularForm["exc_attach"]) {
          this.vmExtraCurricularForm["exc_attach"] = this.parseToJSON(this.vmExtraCurricularForm["exc_attach"])
        }

        if (this.vmExtraCurricularForm["uexcc_org_id"]) {
          this.vmExtraCurricularForm["exc_univ"] = this.vmExtraCurricularForm["uexcc_org_id"]
        }
        this.selectedExcType = this.vmExtraCurricularForm["uexcc_type_key"]
        if (this.selectedExcType == "UNIV_ESSAY") {
          this.populateUniversities()
        }
        this.userClass = UserProfileExcViewResp.resp_data.user_class
      }
      catch (err) {
        console.error("Exception in viewUserProfileExc() and err: ", err.message)
      }
    },

    /**
     * addExtraCurricularActivity
     */
    async addExtraCurricularActivity () {
      const valid = await this.$refs.extraCurrucularForm.validate()
      if (!valid) {
        return
      }

      // send only those links which are not empty
      let videoLinks = []
      for (let i = 0; i < this.video_link_list.length; i++) {
        let videoLink = this.video_link_list[i]
        if (videoLink.url.length > 0) {
          videoLinks.push(videoLink)
        }
      }

      if (videoLinks.length > 0) {
        this.vmExtraCurricularForm["exc_video_link"] = videoLinks
      }

      // Extracting the actual ids from the object that is selected while using vue multiselect
      if (this.vmExtraCurricularForm["exc_title"] && typeof this.vmExtraCurricularForm["exc_title"] == "object") {
        this.vmExtraCurricularForm["exc_title"] = this.vmExtraCurricularForm["exc_title"].value
      }

      if (this.vmExtraCurricularForm["exc_univ"] && typeof this.vmExtraCurricularForm["exc_univ"] == "object") {
        this.vmExtraCurricularForm["exc_univ"] = this.vmExtraCurricularForm["exc_univ"].value
      }

      if (this.vmExtraCurricularForm["exc_type"] && typeof this.vmExtraCurricularForm["exc_type"] == "object") {
        this.vmExtraCurricularForm["exc_type"] = this.vmExtraCurricularForm["exc_type"].value
      }

      if (this.vmExtraCurricularForm["usp_edu_id"] && typeof this.vmExtraCurricularForm["usp_edu_id"] == "object") {
        this.vmExtraCurricularForm["usp_edu_id"] = this.vmExtraCurricularForm["usp_edu_id"].value
      }

      const payload = {
        ...this.vmExtraCurricularForm
      }

      // Append the exc_type_key and exc_type_val in the payload so that it can be used as yjsdocs_code
      let excTypeKey = this.cvExtraCurricularTypeList.find(e => e.value == this.vmExtraCurricularForm["exc_type"])
      if (excTypeKey) {
        payload.exc_type_key = excTypeKey.exc_type_key
        payload.exc_type_val = excTypeKey.label
      }

      if (this.propUserDetails && this.propUserDetails.user_id) {
        payload.gide_user_id = this.propUserDetails.user_id
      }

      if (payload.exc_type_key == "UNIV_ESSAY" || payload.exc_type_key == "COMMONAPP_ESSAY") {
        payload["essay_topics"] = JSON.stringify(this.cvEssayTopicOptions)
      }

      let excpResp = await UserProfileExc.user_profile_excAdd(this, payload)
      if (!excpResp.resp_status) {
        this.toastMsg = excpResp.resp_msg
        this.toastVariant = "danger"
        this.showToast = true
        return
      }

      this.$refs.extraCurrucularForm.reset()
      this.vmExtraCurricularForm = {
        exc_attach: [],
        exc_video_link: []
      }
      if (this.propSchoolDetails || (this.propSchoolDetail && Object.keys(this.propSchoolDetails).length == 0)) {
        // Opened from Education/Resume Bulder
        this.$emit("emitClosedocAddModal", excpResp.resp_data)
      }
      else {
        // opened from yjsdocs List, we will call the api to refresh the docs list
        this.$emit("emitClosedocAddModal", null)
      }
    },

    /**
     * editExtraCurricularActivity
     */
    async editExtraCurricularActivity () {
      let valid = await this.$refs.extraCurrucularForm.validate()
      if (!valid) {
        return
      }

      // send only those links which are not empty
      let videoLinks = []
      for (let i = 0; i < this.video_link_list.length; i++) {
        let videoLink = this.video_link_list[i]
        if (videoLink.url.length > 0) {
          videoLinks.push(videoLink)
        }
      }

      if (videoLinks.length > 0) {
        this.vmExtraCurricularForm["exc_video_link"] = videoLinks
      }

      // Extracting the actual ids from the object that is selected while using vue multiselect
      if (this.vmExtraCurricularForm["exc_title"] && typeof this.vmExtraCurricularForm["exc_title"] == "object") {
        this.vmExtraCurricularForm["exc_title"] = this.vmExtraCurricularForm["exc_title"].value
      }

      if (this.vmExtraCurricularForm["exc_univ"] && typeof this.vmExtraCurricularForm["exc_univ"] == "object") {
        this.vmExtraCurricularForm["exc_univ"] = this.vmExtraCurricularForm["exc_univ"].value
      }

      if (this.vmExtraCurricularForm["exc_type"] && typeof this.vmExtraCurricularForm["exc_type"] == "object") {
        this.vmExtraCurricularForm["exc_type"] = this.vmExtraCurricularForm["exc_type"].value
      }

      if (this.vmExtraCurricularForm["usp_edu_id"] && typeof this.vmExtraCurricularForm["usp_edu_id"] == "object") {
        this.vmExtraCurricularForm["usp_edu_id"] = this.vmExtraCurricularForm["usp_edu_id"].value
      }

      const payload = {
        ...this.vmExtraCurricularForm
      }

      if (this.propUserDetails && this.propUserDetails.user_id) {
        payload.gide_user_id = this.propUserDetails.user_id
      }

      let excpResp = await UserProfileExc.user_profile_excEdit(this, payload)
      if (!excpResp.resp_status) {
        ApiResponse.responseMessageDisplay(this, excpResp)
        return
      }
      this.$emit("emitClosedocAddModal", excpResp.resp_data)
    },

    /**
     * parseToJSON
     */
    parseToJSON (obj) {
      try {
        if (typeof obj === "string") {
          obj = JSON.parse(obj)
        }
        return obj
      }
      catch (jsonParseEx) {
        console.error("Exception in parseToJSON() and err: ", jsonParseEx)
        throw new Error("Invalid JSON, cammot parse")
      }
    },

    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },

    /**
     * addExcVideoLinkInput
     */
    addExcVideoLinkInput () {
      this.video_link_list.push({ url: "" })
    },

    /**
     * removeExcVideoLinkInput
     */
    removeExcVideoLinkInput (index) {
      if (this.video_link_list.length > 1) {
        this.video_link_list.splice(index, 1)
      }
    },

    /**
     * openYjsEditor
     */
    async openYjsEditor (extraCurricular) {
      try {
        let yjsDoc = await YjsDocs.yjsDocsView(this, extraCurricular.yjsdocs_id)
        if (!yjsDoc.resp_status) {
          ApiResponse.responseMessageDisplay(this, yjsDoc)
          return
        }
        yjsDoc = yjsDoc.resp_data.data
        this.documentEditObj = yjsDoc
        this.showEditDocumentModal = true
      }
      catch (err) {
        console.error("Exception in openYjsEditor() and err: ", err.message)
      }
    },
    /**
     * CloseuserYjsdocsHistoryEditModal
     */
    async CloseuserYjsdocsHistoryAddModal (docData) {
      this.vmExtraCurricularForm["exc_desc"] = docData.yjsdoc
      this.showEditDocumentModal = false
    }
  }
}
</script>
<style lang="scss">
  .extraCloseIcon {
    font-size: 18px;
    position: absolute;
    top: 22px;
    cursor: pointer;
    z-index: 99;
    margin-left: -9px;
    color: #e5252c;
  }
  .attachmentCol {
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border: 2px solid aliceblue;
      margin-bottom: 6px;
    }
  }
  .markRow {
    .addIcon {
      float: right;
      margin-top: -42px;
      font-size: 20px;
      position: relative;
      left: 55px;
      cursor: pointer;
      color: #e5252c;
    }
  }
  .rightAlignDiv {
    margin-right: 5%;
    justify-content: end;
    button {
      position: relative;
      left: 25px;
    }
  }
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
