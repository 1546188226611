<template>
  <div class="w-100">
    <!-- Edit User Details -->
    <template v-if="editMode">
      <b-modal v-model="showEditProfileModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true">
        <template #modal-header="">
          <h4 class=" mb-1 instituteTitle_block">
            Personal Information
          </h4>
          <b-button size="sm" class="closeEduModal"  @click="showEditProfileModal = false">
            <i class="fa-solid fa-xmark"></i>
          </b-button>
        </template>
        <ValidationObserver ref="profileForm">
          <form @submit.prevent="onSubmit">
            <h4>Details<sup class="primary-color font-size-16">*</sup></h4><br>

            <div class="row align-items-center">
              <div class="form-group col-sm-6">
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                  <label for="uname">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="vmUserFormData.user_name"
                    minlength="3"
                    maxlength="20"
                    id="uname"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group col-sm-6">
                <label for="uemail">Email</label>
                <input
                  type="text"
                  class="form-control"
                  readonly
                  :disabled="true"
                  id="uemail"
                  v-model="vmUserFormData.user_email"
                />
              </div>

              <div class="col-md-6 mb-3">
                <ValidationProvider name="Gender" rules="required" v-slot="{ errors }">
                <label for="validation_gender">Gender:</label>
                  <select
                    type="text"
                    @change="genderChange"
                    class="form-control"
                    id="validation_gender">
                    <option v-for="(gender, index) of cvUserGenderList" :key="index+gender" :value="gender" :selected="vmUserFormData.user_gender === gender">{{gender}}</option>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group col-md-6 pointer" @click="showOtp()">
                <label for="umobile">Mobile :</label>&nbsp;<i class="fa-solid fa-pencil pointer" @click="showOtp()"></i>
                <ValidationProvider
                  name="Mobile no"
                >
                  <input
                    type="tel"
                    pattern="[1-9]{1}[0-9]{9}"
                    class="form-control pointer"
                    :value="vmUserFormData.user_mobile"
                    disabled
                    name="Mobile no"
                    id="umobile"
                  />
                </ValidationProvider>
              </div>

              <div class="col-md-12 mb-3">
                <h4>Address<sup class="primary-color font-size-16">*</sup></h4><br>
                <ValidationProvider name="Address Line 1" rules="required" v-slot="{ errors }">
                  <label for="validation_Address1">Address line 1</label>
                  <input v-model="vmUserFormData.user_address.addressLineOne" id="validation_Address1" type="text" class="form-control mr-3" placeholder="Enter the address"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="col-md-12 mb-3">
                <ValidationProvider name="Address line 2" v-slot="{ errors }">
                  <label for="validation_Address2">Address line 2</label>
                  <input v-model="vmUserFormData.user_address.addressLineTwo" id="validation_Address2" type="text" class="form-control mr-3" placeholder="Enter the adress"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="col-md-4 mb-3">
                <ValidationProvider name="City" rules="required" v-slot="{ errors }">
                  <label for="validation_city">City</label>
                  <input v-model="vmUserFormData.user_address.user_city" id="validation_city" type="text" class="form-control mr-3" placeholder="Enter city"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="col-md-4 mb-3">
                <ValidationProvider name="State" rules="required" v-slot="{ errors }">
                  <label for="validationlead_country">State</label>
                  <input v-model="vmUserFormData.user_address.user_state" id="validation_state" type="text" class="form-control mr-3" placeholder="Enter state"/>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <div class="col-md-4 mb-3">
                <ValidationProvider name="Country" rules="required" v-slot="{ errors }">
                  <label for="validationlead_country">Country</label>
                  <select v-model="vmUserFormData.user_country" :disabled="showModelAskOtpBox" class="form-control">
                    <template>
                      <option v-for="(country, index) of cvCountryOptions" :key="index+country" :value="country">
                        {{country.country_name}}
                      </option>
                    </template>
                  </select>
                  <span class="text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </form>
        </ValidationObserver>

        <Otp v-if="showOtpModal" :propOpenModal="showOtpModal" @emitCloseOtpModal="closeOtpModal" />

        <b-toast
          v-model="showToast"
          name="Toast"
          :variant="toastVariant"
          :title="toastTitle">
          <div v-html="toastMsg">
          </div>
        </b-toast>
        <template #modal-footer="">
          <button type="button" @click="editProfile" class="btn btn-primary mr-2 pull-right">
              {{ cvSubmitBtn }}
          </button>
        </template>
      </b-modal>
    </template><!-- Edit User Details -->

    <!-- View User Details -->
    <div :key="update" v-if="vmUserFormData && Object.keys(vmUserFormData).length">
      <iq-card class="p-3 document_card" v-if="propDisplayHeader=='1'">
        <div class="row">
          <h4 class="col-12 mb-3 instituteTitle_block pr-0">
            Profile complete status
            <div size="sm" v-if="propUserId == userData.user_id" class="edit_btn float-right" @click="showAlert()">
              <i class="fa fa-info-circle mr-2 primary-color"></i>
            </div>
          </h4>
        </div>
        <hr class="mt-0 document_hr_color"/>
        <div class="progress mb-3">
          <div class="progress-bar" role="progressbar" :style="getActualProgress()" :aria-valuenow="propProfileComplete" aria-valuemin="0" aria-valuemax="100">{{propProfileComplete}}%</div>
        </div>
      </iq-card>
      <iq-card class="p-3 document_card">
        <div class="row" v-if="propDisplayHeader=='1'">
          <h4 class="col-12 mb-3 instituteTitle_block">
            Personal Information
          </h4>
        </div>
        <hr class="mt-0 document_hr_color" v-if="propDisplayHeader=='1'"/>

        <div class="row">
          <div class="col-12">
            <div class="row ml-0">
              <div class="col-12 mb-2 Gpath_heading">
                <h6>Personal Details
                  <button size="sm" v-if="propUserId == userData.user_id" class="edit_btn float-right" @click="showEditProfile()">
                    <i class="fa-solid ri-pencil-line"></i>
                  </button>
                </h6>
              </div>

              <div class="col-md-12">
                <div class="row">
                  <div class="col-12"><i class="fa-solid fa-user mr-2"></i>{{vmUserFormData.user_name}}</div>
                </div>
              </div>

              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-12"><i class="fa-solid fa-envelope mr-2"></i>{{vmUserFormData.user_email}}</div>
                </div>
              </div>

              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-12"><i class="fa-solid fa-phone mr-2"></i>{{vmUserFormData.user_mobile}}</div>
                </div>
              </div>

              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-12"><i class="fa-solid fa-person-half-dress mr-2"></i>{{vmUserFormData.user_gender}}</div>
                </div>
              </div>

              <div class="col-md-12 ">
                <div class="row">
                  <div class="col-12"><i class="fa-solid fa-location-pin mr-2"></i>{{vmUserFormData.user_address && typeof vmUserFormData.user_address === 'string' && vmUserFormData.user_address.includes("XXX") ? vmUserFormData.user_address : parseAddress(vmUserFormData.user_address)}}</div>
                </div>
              </div>
              <div class="col-12 mt-4 Gpath_heading">
                <h6>Parent Details
                  <button size="sm" v-if="propUserId == userData.user_id" class="edit_btn float-right" @click="showEditParent()">
                    <i class="fa-solid ri-pencil-line"></i>
                  </button>
                </h6>
              </div>
              <div class="col-md-12" v-if="vmUserFormData.user_parent_emails && Object.keys(vmUserFormData.user_parent_emails).length > 0">
                <div class="row mb-3" v-for="(parent, index) of vmUserFormData.user_parent_emails" :key="index+parent">
                  <div class="col-12"><i class="fa-solid fa-user-circle-o mr-2"></i>{{parent.parent_name}} ({{ parent.parent_type }})</div>
                  <div class="col-12"><i class="fa-solid fa-envelope mr-2"></i>{{parent.parent_email}}</div>
                  <div class="col-12"><i class="fa-solid fa-phone mr-2"></i>{{parent.parent_mobile}}</div>
                </div>
              </div>
              <div v-else class="col-md-12 pointer">
                <span v-if="propUserId == userData.user_id" @click="showEditParent()">Add Parent Details&nbsp;<label class="primary-color font-size-16">*</label>&nbsp;
                  <button size="sm" v-if="propUserId == userData.user_id" class="edit_btn" @click="showEditParent()">
                    <i class="fa-solid ri-add-circle-fill mt-2"></i>
                  </button>
                </span>
                <span class="mt-2" v-else> Info not available</span>
              </div>
            </div>
          </div>
        </div>
      </iq-card>
    </div><!-- View User Details -->
    <ParentInfo :propUserId="userData.user_id" v-if="showParentInfo" @emitCloseParentEditModel="userParentList" :key="updateParentData"/>

    <AlertBox :propAlertTitle="alertTitle" v-if="showAlertBox" :propAlertDesc="alertDesc" :propOkBtnText="okText" @emitCloseAlertModel="showAlertBox = false" @emitConfirmAlertModel="showAlertBox = false" :propCancelBtnText="cancelText" />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { ValidationProvider } from "vee-validate"
import { User } from "../../FackApi/api/user.js"
import { Countries } from "../../FackApi/api/country.js"
import ApiResponse from "../../Utils/apiResponse.js"
import AlertBox from "../AlertBox.vue"
import { UserParent } from "../../FackApi/api/userParent.js"
import ParentInfo from "./ParentInfo.vue"

import Otp from "../User/Otp.vue"

export default {
  name: "PersonalInfo",
  component: {
    ValidationProvider
  },
  components: {
    Otp,
    AlertBox,
    ParentInfo
  },
  props: {
    propUserId: {
      type: String,
      default: "SYSE"
    },
    propUserData: {},
    propModuleType: null,
    propDisplayHeader: null,
    propProfileComplete: null
  },
  data () {
    return {
      cvUserGenderList: ["Male", "Female", "Other"],
      cvSubmitBtn: "Save Profile",
      vmUserFormData: {
        user_address: {}
      },
      cvCountryOptions: [],
      cvCountrySelectValue: {},
      showOtpModal: false,
      parentList: [],
      loadingFlag: false,
      showModelAskOtpBox: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      editMode: false,
      showEditProfileModal: false,
      update: 0,
      alertTitle: "User Profile Completion",
      alertDesc: `
      Each detail in the profile holds some weight age. The more information you put, the profile percentage increases.<br><br>
      1. Personal Info:<br>
      - About yourself: 20%<br>
      - Address & Parent Details: 20%<br><br>
      2. Education: Each School, Class, Board, Subject & Marks = 20%<br>
      *Information upto 3 classes boosts your completion to 60%`,
      okText: "OK",
      cancelText: "Cancel",
      showAlertBox: false,
      showParentInfo: false,
      updateParentData: 0
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    if (this.propUserId === this.userData.user_id || this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      this.editMode = true
      this.loadRequiredData()
    }
    else {
      this.vmUserFormData = { ...this.propUserData }

      if (this.propUserData && this.propUserData.user_parent_list && this.propUserData.user_parent_list.length) {
        this.parentList = this.propUserData.user_parent_list
      }
    }
  },
  methods: {
    /**
     * getActualProgress
     */
    getActualProgress () {
      return `width: ${this.propProfileComplete}%;`
    },
    /**
     * genderChange
     */
    genderChange (event) {
      this.vmUserFormData.user_gender = event.target.value
    },
    /**
     * parseAddress
     */
    parseAddress (address) {
      if (address) {
        try {
          let parseAddress = address
          if (typeof parseAddress === "string") {
            parseAddress = JSON.parse(address)
          }
          return `${parseAddress.addressLineOne ? parseAddress.addressLineOne + ", " : ""} ${parseAddress.addressLineTwo ? parseAddress.addressLineTwo + ", " : ""}
                  ${parseAddress.user_city ? parseAddress.user_city + ", " : ""} ${parseAddress.user_state ? parseAddress.user_state : ""}`
        }
        catch (error) {
          return address
        }
      }
      else {
        return address
      }
    },
    /**
     * showAlert
     */
    showAlert () {
      this.showAlertBox = true
    },
    /**
     * showEditProfile
     */
    showEditProfile () {
      this.showEditProfileModal = true
    },
    /**
     * showEditParent
     */
    showEditParent () {
      this.showParentInfo = true
    },
    /**
     * showOtp
     */
    showOtp () {
      this.showOtpModal = true
    },
    /**
     * closeOtpModal
     */
    closeOtpModal () {
      if (this.vmUserFormData.user_mobile !== this.userData.user_mobile) {
        this.vmUserFormData.user_mobile = this.userData.user_mobile

        if (this.vmUserFormData.user_country && this.vmUserFormData.user_country.country_id !== this.userData.user_country) {
          const findCountryIndex = this.cvCountryOptions.findIndex(country => country.id === this.userData.country_id)
          if (findCountryIndex >= 0) {
            this.vmUserFormData.user_country = this.cvCountryOptions[findCountryIndex]
          }
        }
      }
      this.showOtpModal = false
    },
    /**
     * loadRequiredData
    */
    async loadRequiredData () {
      this.userView()
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }
        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        const findCountryIndex = this.cvCountryOptions.findIndex(country => country.id === this.userData.country_id)
        if (findCountryIndex >= 0) {
          this.vmUserFormData.user_country = this.cvCountryOptions[findCountryIndex]
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * userView
    */
    async userView () {
      let userId = this.propUserId || this.userData.user_id
      this.loadingFlag = true
      try {
        let userViewResp = await User.userView(this, userId, this.userData.user_id)
        if (userViewResp && !userViewResp.resp_status) {
          this.loadingFlag = false
          return false
        }
        else {
          this.userObj = userViewResp.resp_data.data
          this.vmUserFormData = { ...this.userObj }

          if (!this.vmUserFormData.user_address || typeof this.vmUserFormData.user_address !== "object") {
            this.vmUserFormData.user_address = {}
          }

          this.countryDistinctList()
          this.loadingFlag = false

          this.userParentList()
        }
      }
      catch (err) {
        this.loadingFlag = false
        console.error("Exception occurred at userView() and Exception:", err.message)
      }
    },
    /**
     * userParentList
     */
    async userParentList () {
      try {
        let userId = this.propUserId || this.userData.user_id
        const userParentListResp = await UserParent.user_parentList(this, userId)
        if (userParentListResp.resp_status) {
          this.parentList = userParentListResp.resp_data.data
          this.updateParentList()
        }
      }
      catch (error) {
        console.error("Exception in userParentList", error)
      }
    },
    /**
     * updateParentList
     */
    updateParentList () {
      this.vmUserFormData.user_parent_emails = this.parentList
      this.update += 1
      this.showParentInfo = false
      this.$emit("emitRefreshUserDetails")
    },
    /**
     * editProfile
     * This method is used to edit the profile and mobile no
     */
    async editProfile () {
      try {
        const valid = await this.$refs.profileForm.validate()
        if (!valid) {
          return
        }

        if (
          this.vmUserFormData.user_mobile &&
          this.vmUserFormData.user_mobile !== this.userData.user_mobile
        ) {
          this.showOtpModal = true
        }

        let updateStoreData = {
          country_id: this.vmUserFormData.user_country.id,
          country_name: this.vmUserFormData.user_country.country_name,
          user_id: this.vmUserFormData.user_id,
          user_name: this.vmUserFormData.user_name,
          user_email: this.vmUserFormData.user_email,
          user_mobile: this.vmUserFormData.user_mobile,
          user_gender: this.vmUserFormData.user_gender,
          user_city: this.vmUserFormData.user_city,
          user_country: this.vmUserFormData.user_country.id,
          user_address: this.vmUserFormData.user_address ? JSON.stringify(this.vmUserFormData.user_address) : ""
        }

        let profileEditResp = await User.userEdit(this, updateStoreData)
        await ApiResponse.responseMessageDisplay(this, profileEditResp)
        if (profileEditResp && !profileEditResp.resp_status) {
          return false
        }

        if (profileEditResp && profileEditResp.resp_status) {
          if (profileEditResp.resp_data.user_image) {
            updateStoreData.user_image = profileEditResp.resp_data.user_image
          }

          this.$store.commit("User/setUserProfile", { ...this.userData, ...updateStoreData })

          this.showEditProfileModal = false

          this.$emit("emitRefreshUserDetails")
        }
      }
      catch (err) {
        console.error(
          "Exception occurred at editprofile() and Exception",
          err.message
        )
      }
    }
  },
  watch: {
    propUserId () {
      this.userView()
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-solid {
  color: var(--iq-primary);
}
.deleteIconSpan {
  margin-left: 15px;
}
.educationAddIcon {
  float: right;
}
.edit_btn{
  background: transparent;
  border: 0px;
  font-size: 20px;
  position: relative;
  top: -5px;
}
</style>
