<template>
  <div class="w-100">
    <template>
      <b-modal v-model="showEditParentProfileModal" no-close-on-backdrop no-close-on-esc size="xl" :noCloseOnBackdrop="true" @hide="closeParentInfoModel">
        <template #modal-header="">
          <h4 class=" mb-1 instituteTitle_block">
            Parent Information
          </h4>
          <b-button size="sm" class="closeEduModal"  @click="showEditParentProfileModal = false">
            <i class="fa-solid fa-xmark"></i>
          </b-button>
        </template>
        <ValidationObserver ref="profileForm">
          <form @submit.prevent="onSubmit">
            <div class="row align-items-center">
                <template>
                    <div class="row w-100 mt-4 pl-2">
                      <div class="col-12 mb-3 mt-2">
                        <h4>Parent Email<sup class="primary-color font-size-16">*</sup>&nbsp;&nbsp;
                          <span class="educationAddIcon1" @click="showAddParentModel()"><i class="fa-solid fa-circle-plus pointer"></i></span>
                        </h4><br>
                      </div>
                      <div class="col-md-12 mb-3" v-for="(item, index) of parentList" :key="index+item">
                        <label for="validation_website">{{item.parent_name}} ({{item.parent_email}})
                          <i v-if="item.parent_email_verified != 1" title="Email is not verified" class="fa-solid fa-circle-exclamation pointer" style="color:#ffc107;"></i>
                          <i v-if="item.parent_mobile_verified != 1" title="Mobile is not verified" class="fa-solid fa-circle-exclamation pointer ml-1" style="color:#ffc107;"></i>
                          <span class="educationAddIcon pointer" @click="showEditParentModel(item)">&nbsp;&nbsp;<i class="fa-solid fa-pencil"></i></span>
                          <span class="educationAddIcon pointer" @click="showParentModelDeleteDialog(item)">&nbsp;&nbsp;<i class="fa-solid fa-trash"></i></span>
                        </label>
                      </div>
                    </div>
                </template>
            </div>
          </form>
        </ValidationObserver>

        <!-- Add first time parent details -->
        <b-modal v-model="showAddParent" title="Parent Detail">
          <template>
            <div class="row w-100">
              <ValidationObserver ref="parentForm" class="row w-100">
                <div class="form-group col-12">
                  <label for="parent_name">Name</label>
                  <ValidationProvider name="Name"  v-slot="{ errors }" rules="required|regex:^[a-zA-Z]+([ '.-][a-zA-Z0-9]+)*$">
                    <input minlength="3" maxlength="20" v-model="vmParentForm['parent_name']" type="text" class="form-control mb-0" id="parent_name">
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form-group col-12">
                  <label for="parent_type">Type (Mother/Father/Guardian)</label>
                  <ValidationProvider name="Type"  v-slot="{ errors }" rules='required|min:2'>
                    <multiselect :disabled="vmParentForm.upar_id && showVerifyEmail ? true : false" v-model="vmParentForm.parent_type" :searchable="false" :show-labels="false" id="parent_type" :options="parentTypeOptions">
                    </multiselect>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- Email input box -->
                <div class="form-group col-12 mt-4">
                  <label for="parent_email">Email</label>
                  <ValidationProvider name="Email"  v-slot="{ errors }" rules='required|min:2'>
                    <input :disabled="vmParentForm.upar_id && showVerifyEmail ? true : false" v-model="vmParentForm['parent_email']" type="text" class="form-control mb-0" id="parent_email">
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span v-if="vmParentForm.upar_id && (vmParentForm['parent_email'] != preVmParentForm['parent_email'] || vmParentForm['parent_email_verified'] != '1')"><b-button class="mt-2" size="sm" variant="primary" @click="resentOtp('EMAIL')">{{ "Re-Send OTP" }}</b-button></span>
                </div><!-- Email input box -->

                <!-- Email verification code input box -->
                <div class="form-group col-12" v-if="vmParentForm.upar_id && showVerifyEmail">
                  <label for="code">OTP sent to your parent's Email Id</label>
                  <ValidationProvider name="Email verification Code"  v-slot="{ errors }" rules='required|min:4'>
                    <input v-model="vmParentForm['email_code']" type="text" class="form-control mb-0" id="code" placeholder="Enter Email OTP Here">
                    <b-button class="mt-2" size="sm" variant="primary" @click="addEditUserParent('EMAIL')">Verify</b-button>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div><!-- Email verification code input box -->

                <!-- Mobile country code dropdown -->
                <div class="form-group col-12 mt-4">
                  <label for="validation_country">Country Code</label>
                    <div class="flex-row">
                      <ValidationProvider
                        name="select one"
                        rules="required"
                        v-slot="{ errors }">
                          <select v-model="vmParentForm['country_code']" class="form-control w-100" id="validation_country">
                          <template class="w-100">
                            <option class="w-100" v-for="(country, index) of cvCountryOptions" :key="index+'1'" :value="country.country_phone_code">
                              {{country.country_name}} (+ {{country.country_phone_code}})
                            </option>
                          </template>
                        </select>
                        <span class="text-danger"> {{ errors[0] }} </span>
                      </ValidationProvider>
                    </div>
                </div><!-- Mobile country code dropdown -->

                <!-- Mobile input box -->
                <div class="form-group col-12">
                  <label for="parent_mobile">Mobile</label>
                  <ValidationProvider name="Mobile"  v-slot="{ errors }" rules='required|min:2'>
                    <input :disabled="vmParentForm.upar_id && showVerifyMobile ? true : false" v-model="vmParentForm['parent_mobile']" type="number" class="form-control mb-0" id="parent_mobile">
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span v-if="vmParentForm.upar_id && (vmParentForm['parent_mobile'] != preVmParentForm['parent_mobile'] || vmParentForm['parent_mobile_verified'] != '1')"><b-button class="mt-2" size="sm" variant="primary" @click="resentOtp('MOBILE')">{{ "Re-Send OTP" }}</b-button></span>
                </div><!-- Mobile input box -->

                <!-- Mobile verification code input box -->
                <div class="form-group col-12 mt-2" v-if="vmParentForm.upar_id && showVerifyMobile">
                  <label for="code">OTP sent to your parent's Mobile No. and/or Whatsapp</label>
                  <ValidationProvider name="Mobile verification Code"  v-slot="{ errors }" rules='required|min:4'>
                    <input v-model="vmParentForm['mobile_code']" type="text" class="form-control mb-0" id="code" placeholder="Enter Mobile OTP Here">
                    <b-button class="mt-2" size="sm" variant="primary" @click="addEditUserParent('MOBILE')">Verify</b-button>
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div><!-- Mobile verification code input box -->
              </ValidationObserver>
            </div>
          </template>
          <template #modal-footer="">
            <b-button size="sm"  @click="hideParentAdd()">Close</b-button>

            <b-button v-if="!vmParentForm.upar_id && vmParentForm['parent_email_verified'] != '1' && vmParentForm['parent_mobile_verified'] != '1'" size="sm" variant="primary" @click="addEditUserParent()">Add Parent</b-button>
            <b-button v-else size="sm" variant="primary" @click="addEditUserParent()">Edit Parent</b-button>
          </template>
        </b-modal>
        <!-- Add first time parent details -->

        <!-- Parent Details Delete -->
        <b-modal v-model="showModelParentDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
          <p>
            {{cvDelMessage}}
          </p>
          <template #modal-footer="">
            <b-button size="sm" @click="showModelParentDelete = false">
              {{cvbtnModalCancel}}
            </b-button>
            <b-button size="sm" variant="primary" @click="parentInfoDelete()">
              {{cvbtnModalDelOk}}
            </b-button>
          </template>
        </b-modal> <!-- Parent Details Delete -->

        <b-toast
          v-model="showToast"
          name="Toast"
          :variant="toastVariant"
          :title="toastTitle">
          <div v-html="toastMsg">
          </div>
        </b-toast>
      </b-modal>
    </template>

    <AlertBox :propAlertTitle="alertTitle" v-if="showAlertBox" :propAlertDesc="alertDesc" :propOkBtnText="okText" @emitCloseAlertModel="showAlertBox = false" @emitConfirmAlertModel="showAlertBox = false" :propCancelBtnText="cancelText" />

  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Multiselect from "vue-multiselect"
import { ValidationProvider } from "vee-validate"
import { User } from "../../FackApi/api/user.js"
// import { Countries } from "../../FackApi/api/country.js"
import ApiResponse from "../../Utils/apiResponse.js"
import AlertBox from "../AlertBox.vue"
import { UserParent } from "../../FackApi/api/userParent.js"
import { Countries } from "../../FackApi/api/country.js"
import { userEmailValidation } from "../../Utils/validation.js"

export default {
  name: "PersonalInfo",
  component: {
    ValidationProvider
  },
  components: {
    Multiselect,
    AlertBox
  },
  props: {
    propUserId: {
      type: String
    },
    propUserData: {},
    propModuleType: null,
    propDisplayHeader: null,
    propProfileComplete: null,
    propshowEditParentProfileModal: null
  },
  data () {
    return {
      vmUserFormData: {
        user_address: {}
      },
      cvCountryOptions: [],
      showOtpModal: false,
      showAddParent: false,
      showVerifyEmail: false,
      showVerifyMobile: false,
      editParentModelShow: false,
      parentTypeOptions: [ "Mother", "Father", "Guardian" ],
      parentList: [],
      vmParentForm: {
        upar_id: null
      },
      preVmParentForm: {},
      loadingFlag: false,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "User",
      editMode: false,
      showEditParentProfileModal: true,
      update: 0,
      alertTitle: "User Profile Completion",
      alertDesc: `
      Each detail in the profile holds some weight age. The more information you put, the profile percentage increases.<br><br>
      1. Personal Info:<br>
      - About yourself: 20%<br>
      - Address & Parent Details: 20%<br><br>
      2. Education: Each School, Class, Board, Subject & Marks = 20%<br>
      *Information upto 3 classes boosts your completion to 60%`,
      okText: "OK",
      cancelText: "Cancel",
      showAlertBox: false,
      cvDelMessage: "Are you sure?",
      cvbtnModalCancel: "Cancel",
      cvbtnModalDelOk: "Delete",
      showModelParentDelete: false
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  mounted () {
    this.countryDistinctList()
    if (this.propUserId === this.userData.user_id || this.userData.user_role == "USERROLE11111" || this.userData.user_role == "USERROLE11118") {
      this.editMode = true
      this.loadRequiredData()
    }
    else {
      this.vmUserFormData = { ...this.propUserData }
      this.preVmParentForm = { ...this.propUserData }

      if (this.propUserData && this.propUserData.user_parent_list && this.propUserData.user_parent_list.length) {
        this.parentList = this.propUserData.user_parent_list
      }
    }
  },
  methods: {
    /**
     * getActualProgress
     */
    getActualProgress () {
      return `width: ${this.propProfileComplete}%;`
    },
    /**
     * genderChange
     */
    genderChange (event) {
      this.vmUserFormData.user_gender = event.target.value
    },
    /**
     * parseAddress
     */
    parseAddress (address) {
      if (address) {
        try {
          let parseAddress = address
          if (typeof parseAddress === "string") {
            parseAddress = JSON.parse(address)
          }
          return `${parseAddress.addressLineOne ? parseAddress.addressLineOne + ", " : ""} ${parseAddress.addressLineTwo ? parseAddress.addressLineTwo + ", " : ""}
                  ${parseAddress.user_city ? parseAddress.user_city + ", " : ""} ${parseAddress.user_state ? parseAddress.user_state : ""}`
        }
        catch (error) {
          return address
        }
      }
      else {
        return address
      }
    },
    /**
     * showAlert
     */
    showAlert () {
      this.showAlertBox = true
    },
    /**
     * showEditProfile
     */
    showEditParentProfile () {
      this.showEditParentProfileModal = true
    },
    /**
     * loadRequiredData
    */
    async loadRequiredData () {
      this.userView()
      this.userParentList()
    },
    /**
     * userView
    */
    async userView () {
      let userId = this.propUserId || this.userData.user_id
      this.loadingFlag = true
      try {
        let userViewResp = await User.userView(this, userId, this.userData.user_id)
        if (userViewResp && !userViewResp.resp_status) {
          this.loadingFlag = false
          return false
        }
        else {
          this.userObj = userViewResp.resp_data.data
          this.vmUserFormData = { ...this.userObj }

          if (!this.vmUserFormData.user_address || typeof this.vmUserFormData.user_address !== "object") {
            this.vmUserFormData.user_address = {}
          }

          this.loadingFlag = false

          this.userParentList()
        }
      }
      catch (err) {
        this.loadingFlag = false
        console.error("Exception occurred at userView() and Exception:", err.message)
      }
    },
    /**
     * showEditParentModel
    */
    showEditParentModel (parentForm) {
      if (parentForm.parent_email_verified != 1) {
        this.showVerifyEmail = true
      }
      if (parentForm.parent_mobile_verified != 1) {
        this.showVerifyMobile = true
      }
      this.editParentModelShow = true
      this.preVmParentForm = { ...parentForm }
      this.showAddParent = true
      this.vmParentForm = parentForm
    },
    /**
     * hideParentAdd
     */
    hideParentAdd () {
      this.showVerifyEmail = false
      this.editParentModelShow = false
      this.showAddParent = false
      if (this.parentList && this.parentList.length) {
        const findIndex = this.parentList.findIndex(pare => pare.upar_id === this.preVmParentForm.upar_id)
        if (findIndex >= 0) {
          if (this.vmParentForm.parent_email === this.preVmParentForm.parent_email) {
            this.parentList[findIndex] = { ...this.preVmParentForm }
          }
          else {
            this.vmParentForm.parent_email_verified = 0
            this.parentList[findIndex] = this.vmParentForm
          }
        }
      }
      this.updateParentList()
      this.preVmParentForm = {}
    },
    /**
     * showAddParentModel
     */
    showAddParentModel () {
      this.vmParentForm = {}
      this.showAddParent = true
    },
    /**
     * addEditUserParent
     */
    async addEditUserParent (mode = null) {
      let vmParentFormCountryCodeCopy = this.vmParentForm["country_code"]

      try {
        if (mode != null && this.vmParentForm.upar_id && (this.showVerifyEmail || this.showVerifyMobile)) {
          if (mode == "MOBILE") {
            if (this.vmParentForm.country_code) {
              vmParentFormCountryCodeCopy = this.vmParentForm.country_code
              this.vmParentForm.country_code = this.vmParentForm.country_code
            }
            else {
              this.toastMsg = "Please select an appropriate country code"
              this.toastVariant = "danger"
              this.showToast = true
              return
            }
          }

          if (!this.vmParentForm.email_code && !this.vmParentForm.mobile_code) {
            if (this.showVerifyEmail) {
              this.toastMsg = "Please provide the email verification code"
              this.toastVariant = "danger"
              this.showToast = true
              this.vmParentForm.country_code = vmParentFormCountryCodeCopy
              return
            }
            else if (this.showVerifyMobile) {
              this.toastMsg = "Please provide the mobile verification code"
              this.toastVariant = "danger"
              this.showToast = true
              this.vmParentForm.country_code = vmParentFormCountryCodeCopy
              return
            }
          }

          const userParentAddResp = await UserParent.user_parentVerifyEmailAndMobile(this, { parent_email: this.vmParentForm.parent_email, email_code: this.vmParentForm.email_code, mobile_code: this.vmParentForm.mobile_code, upar_id: this.vmParentForm.upar_id })
          if (userParentAddResp.resp_status) {
            if (mode == "EMAIL") {
              this.vmParentForm["email_code"] = ""
              this.showVerifyEmail = false
              this.vmParentForm.parent_email_verified = 1
            }
            else if (mode == "MOBILE") {
              this.vmParentForm["mobile_code"] = ""
              this.showVerifyMobile = false
              this.vmParentForm.parent_mobile_verified = 1
            }

            this.preVmParentForm = { ...this.vmParentForm }

            const findPar = this.parentList.findIndex(par => par.upar_id === this.vmParentForm.upar_id)
            if (findPar >= 0) {
              this.parentList[findPar] = userParentAddResp.resp_data
            }
            else {
              this.parentList.push(userParentAddResp.resp_data)
            }

            // this.vmParentForm = {}
            // this.preVmParentForm = {}
            // this.showAddParent = false
            // this.showVerifyEmail = false
            // this.showVerifyMobile = false
            this.editParentModelShow = false
          }
          await ApiResponse.responseMessageDisplay(this, userParentAddResp)
        }
        else if (this.editParentModelShow && this.vmParentForm.upar_id) {
          const userParentEditResp = await UserParent.user_parentEdit(this, this.vmParentForm)
          if (userParentEditResp.resp_status) {
            // Email
            if (this.preVmParentForm.parent_email === this.vmParentForm.parent_email && Number(userParentEditResp.resp_data.parent_email_verified) === 1) {
              this.showVerifyEmail = false
            }
            else {
              this.showVerifyEmail = true
              this.vmParentForm = userParentEditResp.resp_data
              this.vmParentForm.country_code = vmParentFormCountryCodeCopy
              return
            }

            // Mobile
            if (this.preVmParentForm.parent_mobile === this.vmParentForm.parent_mobile && Number(userParentEditResp.resp_data.parent_mobile_verified) === 1) {
              this.showVerifyMobile = false

              // These objects will be reset at the end of validating all the fields
              this.vmParentForm = {}
              this.preVmParentForm = {}
            }
            else {
              this.showVerifyMobile = true
              this.vmParentForm = userParentEditResp.resp_data
              this.vmParentForm.country_code = vmParentFormCountryCodeCopy
              return
            }
            this.showAddParent = false
          }
          await ApiResponse.responseMessageDisplay(this, userParentEditResp)
        }
        else {
          // Do not allow emails with "+" sign within them but allow testing email ids
          if (!userEmailValidation(this, this.vmParentForm.parent_email)) {
            this.vmParentForm.country_code = vmParentFormCountryCodeCopy
            return
          }

          // set the country code by destructuring the object
          if (this.vmParentForm["country_code"]) {
            this.vmParentForm["country_code"] = this.vmParentForm["country_code"]
          }
          else {
            this.toastMsg = "Please select an appropriate country code"
            this.toastVariant = "danger"
            this.showToast = true
            return
          }

          const userParentAddResp = await UserParent.user_parentAdd(this, this.vmParentForm)
          if (userParentAddResp.resp_status) {
            this.showVerifyEmail = true
            this.showVerifyMobile = true
            this.vmParentForm = userParentAddResp.resp_data
          }
          else {
            await ApiResponse.responseMessageDisplay(this, userParentAddResp)
          }
        }
        this.vmParentForm.country_code = vmParentFormCountryCodeCopy
        this.updateParentList()
      }
      catch (error) {
        console.error("Exception in addEditUserParent", error)
      }
    },
    /**
     * userParentList
     */
    async userParentList () {
      try {
        let userId = this.propUserId || this.userData.user_id
        const userParentListResp = await UserParent.user_parentList(this, userId)
        if (userParentListResp.resp_status) {
          this.parentList = userParentListResp.resp_data.data
          this.updateParentList()
        }
      }
      catch (error) {
        console.error("Exception in userParentList", error)
      }
    },
    /**
     * countryDistinctList
     */
    async countryDistinctList () {
      try {
        let matchWithQuery = "distinct country"
        let countryDistinctListResp = await Countries.countryList(this, matchWithQuery)
        if (countryDistinctListResp && !countryDistinctListResp.resp_status) {
          return false
        }

        this.cvCountryOptions = countryDistinctListResp.resp_data.data
        if (this.userData && this.userData.country_id) {
          const findIndex = this.cvCountryOptions.findIndex(ele => ele.id === this.userData.country_id)
          if (findIndex >= 0) {
            this.vmParentForm["country_code"] = this.cvCountryOptions[findIndex]
            if (this.userData.user_mobile && this.vmParentForm["parent_mobile"] && this.vmParentForm["parent_mobile"].country_phone_code) {
              let regx = new RegExp(this.vmParentForm["parent_mobile"].country_phone_code, "gm")
              this.vmParentForm["parent_mobile"] = this.userData.user_mobile.replace(regx, "")
            }
          }
        }
      }
      catch (err) {
        console.error("Exception occurred at countryDistinctList() and Exception:", err.message)
      }
    },
    /**
     * updateParentList
     */
    updateParentList () {
      this.vmUserFormData.user_parent_emails = this.parentList
      this.update += 1
    },
    /**
     * closeParentInfoModel
     */
    closeParentInfoModel () {
      this.showEditParentProfileModal = false
      this.$emit("emitCloseParentEditModel", false)
    },
    /**
     * resentOtp
     */
    async resentOtp (mode) {
      try {
        let resp = null
        let vmParentFormCountryCodeCopy = null

        switch (mode) {
          case "EMAIL":
            resp = await UserParent.user_parentSendEmailOtp(this, this.vmParentForm)
            this.showVerifyEmail = true
            break
          case "MOBILE":
            if (this.vmParentForm.country_code) {
              vmParentFormCountryCodeCopy = this.vmParentForm.country_code
              this.vmParentForm.country_code = this.vmParentForm.country_code.country_phone_code
            }
            else {
              this.toastMsg = "Please select an appropriate country code"
              this.toastVariant = "danger"
              this.showToast = true
              return
            }

            resp = await UserParent.user_parentSendMobileOtp(this, this.vmParentForm)
            this.showVerifyMobile = true
            break
          default:
            console.error("Invalid mode of communication")
        }

        ApiResponse.responseMessageDisplay(this, resp)
        this.vmParentForm.country_code = vmParentFormCountryCodeCopy
      }
      catch (err) {
        console.error("Exception in resentOtp() and err: ", err.message)
      }
    },
    /**
     * showParentModelDeleteDialog
     */
    showParentModelDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelParentDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showParentModelDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * parentInfoDelete
     */
    async parentInfoDelete () {
      try {
        let userParentDelResp = await UserParent.user_parentDelete(this, this.delObj.upar_id)
        await ApiResponse.responseMessageDisplay(this, userParentDelResp)
        if (userParentDelResp && !userParentDelResp) {
          this.showModelParentDelete = false
          return false
        }
        let index = this.parentList.indexOf(this.delObj)
        this.parentList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelParentDelete = false
      }
      catch (err) {
        console.error("Exception occurred at ParentInfoDelete() and Exception:", err.message)
      }
    }
  },
  watch: {
    propUserId () {
      this.userView()
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-solid {
  color: var(--iq-primary);
}
.deleteIconSpan {
  margin-left: 15px;
}
.educationAddIcon {
  float: right;
}
.edit_btn{
  background: transparent;
  border: 0px;
  font-size: 20px;
  position: relative;
  top: -5px;
}
</style>
